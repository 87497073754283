import React, {useState} from "react";
import "./App.css";
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import GridComponent from "./GridComponent";
import EventDetailComponent from "./EventDetailComponent";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "TestingManualCreation",
        endpoint: "https://5db57p1ak6.execute-api.us-east-2.amazonaws.com/dev/"
      }
    ]
  }
});


export default function App() {
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const handleShowEventDetail = (eventId) => {
    setSelectedEventId(eventId);
    setShowEventDetail(true);
  };

  const handleHideEventDetail = () => {
    setSelectedEventId(null);
    setShowEventDetail(false);
  };

  return (
        <Authenticator hideSignUp={true} className={"centered-div"}>
          {({ signOut, user }) => (
              <main>
                <nav className="navbar">
                  <div className="navbar-brand">
                    <h1>Hello {user.username}</h1>
                  </div>
                  <div className="navbar-menu">
                    <button className="sign-out-button" onClick={signOut}>Sign out</button>
                  </div>
                </nav>
                <div className="App">
                  <GridComponent onShowEventDetail={handleShowEventDetail} />
                  {showEventDetail && (
                      <EventDetailComponent
                          eventId={selectedEventId}
                          onHideEventDetail={handleHideEventDetail}
                      />
                  )}
                </div>
              </main>
          )}
        </Authenticator>
  );
}