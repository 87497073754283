import React, { useEffect, useState } from "react";
import {fetchApiData} from "./Pge.Reports.Api";
import "./GridComponent.css";

const GridComponent = (props) => {
    const { onShowEventDetail } = props;
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        async function fetchData() {
          const apiResponse = await fetchApiData();
          if (apiResponse) {
            setGridData(JSON.parse(apiResponse.body)); // Assuming the API response is an array of data
          }
        }
        fetchData();
      }, []);

    const [expandedRows, setExpandedRows] = useState({});

    const toggleRowExpansion = (requestId) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [requestId]: !prevExpandedRows[requestId],
        }));
    };

    const getRatingEnumLabel = (ratingEnum) => {
        if (ratingEnum === 0) {
            return "Pass";
        } else if (ratingEnum === 2) {
            return "Fail";
        } else {
            return "Unknown";
        }
    };

    const getPassFailCounts = (ratings) =>{
        var passCount = 0;
        var failCount = 0;
        ratings.forEach((rating) => {
            if (rating.RatingEnum === 0) {
                passCount++;
            } else if (rating.RatingEnum === 2) {
                failCount++;
            }
        });
        return "Pass: " + passCount + " Fail: " + failCount;

    }

    const handleButtonClick = (eventId) => {
        onShowEventDetail(eventId);
    };

    const handleMouseEnter = (e, description) => {
        const tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        tooltip.textContent = description;
        document.body.appendChild(tooltip);

        const rect = e.target.getBoundingClientRect();
        tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight}px`;
        tooltip.style.left = `${rect.left + window.scrollX}px`;
    };

    const handleMouseLeave = () => {
        const tooltip = document.querySelector(".tooltip");
        if (tooltip) {
            document.body.removeChild(tooltip);
        }
    };

    const formatCurrency = (amount) => {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD", // Change this to your desired currency code
            minimumFractionDigits: 2,
        });

        return formatter.format(amount);
    };

    const getRatingNumericValueById = (ratings, id) => {
        var ratingNumericValue = 0;
        ratings.forEach((rating) => {
            if (rating.RatingId === id) {
                ratingNumericValue = rating.NumericResult;
            }
        });
        return ratingNumericValue;
    }

    const renderRatings = (ratings) => {
        return (
            <div className="sub-row">
                <h3>Ratings</h3>
                <h4>{getPassFailCounts(ratings)}</h4>
                <table className="ratings-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Pass/Fail</th>
                        <th>Reason</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ratings.map((rating) => (
                        <tr key={rating.RatingId}>
                            <td>
                                <div
                                className="rating-description"
                                onMouseEnter={(e) => handleMouseEnter(e, rating.RatingDescription)}
                                onMouseLeave={handleMouseLeave}><span className="tooltip-indicator">ℹ️</span>
                                </div>
                            </td>
                            <td>{rating.RatingId}</td>
                            <td>{rating.RatingName}</td>
                            <td>{getRatingEnumLabel(rating.RatingEnum)}</td>
                            <td>{rating.RatingReason}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderFundsRequestInfo = (gridData)  => {
        var totalRequestAmount = 0;
        var totalRequestCount = 0;
        var totalEventsCount = 0;
        var eventIds = [];

        gridData.forEach((item) => {
           totalRequestCount++;
             totalRequestAmount += item.RequestAmount;
             if(!eventIds.includes(item.EventId)){
                 totalEventsCount++;
                 eventIds.push(item.EventId);
             }
        });
        return (
            <div className="grid-container">
                <table className="grid-table">
                    <thead>
                        <tr>
                            <th>Total Requests</th>
                            <th>Total Events</th>
                            <th>Total Requests Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{totalRequestCount}</td>
                            <td>{totalEventsCount}</td>
                            <td>{formatCurrency(totalRequestAmount)}</td>
                        </tr>
                        </tbody>
                </table>
            </div>
        );

    }

    return (
        <div className="grid-container">
            <h1>Funds Request Grid</h1>

            {renderFundsRequestInfo(gridData)}

            <table className="grid-table">
                <thead>
                <tr>
                    <th></th>
                    <th>Request ID</th>
                    <th>Request Amount</th>
                    <th>Event ID</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Organizer Verified</th>
                    <th>101</th>
                    <th>Details</th>
                </tr>
                </thead>
                <tbody>
                {gridData.map((item) => (
                    <React.Fragment key={item.RequestId}>
                        <tr>
                            <td className="expand-cell">
                                <button
                                    className="expand-button"
                                    onClick={() => toggleRowExpansion(item.RequestId)}
                                >
                                    {expandedRows[item.RequestId] ? "-" : "+"}
                                </button>
                            </td>
                            <td>{item.RequestId}</td>
                            <td>{formatCurrency(item.RequestAmount)}</td>
                            <td>{item.EventId}</td>
                            <td>{item.EventName}</td>
                            <td>{new Date(item.EventDate).toLocaleDateString()}</td>
                            <td>{item.OrganizerVerified}</td>
                            <td>{getRatingNumericValueById(item.Ratings, 101)}</td>
                            <td>
                                <button onClick={() => handleButtonClick(item.EventId)}>
                                Show Event Detail
                                </button>
                            </td>
                        </tr>
                        {expandedRows[item.RequestId] && (
                            <tr>
                                <td colSpan="2"></td>
                                <td colSpan="6">{renderRatings(item.Ratings)}</td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </div>
        
    );
};


export default GridComponent;