import {API} from "aws-amplify";
import {Auth} from "aws-amplify";
import {AWS} from 'aws-sdk';

const fetchApiData = async () => {
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
        }
    };

    try {
      const apiResponse = await API.get('TestingManualCreation', 'GetRatings', myInit);
      return apiResponse;
    } catch (error) {
      console.error('API Error:', error);
      return null;
    }
  };

const fetchEventData = async (eventId) => {
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
        }
    };

    const queryParams = {
        eventId: eventId
    };

    try {
        const apiResponse = await API.get('TestingManualCreation', 'eventDetail', { ...myInit, queryStringParameters: queryParams });
        return apiResponse;
    } catch (error) {
        console.error('API Error:', error);
        return null;
    }
};

const invokeLambda = async () => {
    const lambda = new AWS.lambda();
    const lambdaFunctionARN = process.env.REACT_APP_LAMBDA_FUNCTION_ARN;
    const params = {
        FunctionName: lambdaFunctionARN,
        InvocationType: 'RequestResponse', // Or 'Event' if you want asynchronous execution
        Payload: JSON.stringify({ /* Your payload data here */ }),
    };

    try {
        const response = await lambda.invoke(params).promise();
        const result = JSON.parse(response.Payload);
        console.log(result);
    } catch (error) {
        console.error('Error invoking Lambda function:', error);
    }
};

export { fetchApiData, invokeLambda, fetchEventData };