import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {fetchEventData} from "./Pge.Reports.Api";

const EventDetailComponent = (props) => {
    const { eventId, onHideEventDetail } = props;

    const [eventData, setEventData] = useState({
        event: null,
        fundsRequests: [],
        organizer: null,
        registrationPackages: [],
        registrations: []
    });

    useEffect(() => {
        async function fetchData() {
            const apiResponse = await fetchEventData(eventId);
            if (apiResponse) {
                setEventData(JSON.parse(apiResponse.body)); // Assuming the API response is an array of data
            }
        }
        fetchData();
    }, [eventId]);

    const handleCloseClick = () => {
        onHideEventDetail();
    };


    return (
        <div>
            <h2>Event Details</h2>
            {eventData.event ? (
            <div>
                <div>
                    <strong>Event Name:</strong> {eventData.event.name}
                    {/* Display other event details here */}
                </div>
                <h2>Organizer</h2>
                <div>
                    <strong>Organizer Name:</strong> {eventData.organizer.first_name} {eventData.organizer.last_name}
                        {/* Display other organizer details here */}
                    </div>

                    <h2>Tabbed Grids</h2>
                    <Tabs>
                        <TabList>
                            <Tab>Funds Requests</Tab>
                            <Tab>Registrations</Tab>
                            <Tab>Registration Packages</Tab>
                        </TabList>

                        <TabPanel>
                            <h3>Funds Requests</h3>
                            {/* Map through fundsRequests and display details */}
                        </TabPanel>

                        <TabPanel>
                            <h3>Registrations</h3>
                            {/* Map through registrations and display details */}
                        </TabPanel>

                        <TabPanel>
                            <h3>Registration Packages</h3>
                            {/* Map through registrationPackages and display details */}
                        </TabPanel>
                    </Tabs>
                    <button onClick={handleCloseClick}>Close</button>
            </div>  )
                : (<div>Loading event data...</div>)
            }
        </div>

    );
};

export default EventDetailComponent;